import { FaBars, FaWindowClose } from 'react-icons/fa';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from './authCtx';
const logo = '/ul-logo.png';

function About() {
    return (
        <div className="relative group my-auto border border-transparent hover:border-base-gray hover:bg-base-gray">
            <div className="text-center text-white text-xl relative mx-auto w-[150px] hover:text-UL-green">
                About
            </div>

            <ul className="absolute hidden group-hover:block m-auto left-0 right-0 z-50 border border-base-gray bg-black">
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/team"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Our Team
                    </Link>
                </li>
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/mission"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Our Mission
                    </Link>
                </li>
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/story"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Our Story
                    </Link>
                </li>
            </ul>
        </div>
    );
}

function Services() {
    return (
        <div className="relative group dropdown my-auto border border-transparent hover:border-base-gray hover:bg-base-gray">
            <div className="text-center text-white text-xl relative mx-auto w-[210px] hover:text-UL-green">
                Services
            </div>
            <ul className="group-hover:block dropdown-menu absolute hidden m-auto left-0 right-0 z-30 w-[210px] border border-base-gray">
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/unlocked"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        UnlockEd
                    </Link>
                </li>
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/coding-education"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Coding Education
                    </Link>
                </li>
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/tech-consulting"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Tech Consulting
                    </Link>
                </li>
            </ul>
        </div>
    );
}

function LearnMore() {
    return (
        <div className="relative group dropdown my-auto border border-transparent hover:border-base-gray hover:bg-base-gray">
            <div className="text-center text-white text-xl relative mx-auto w-[210px] hover:text-UL-green">
                Learn More
            </div>
            <ul className="group-hover:block dropdown-menu absolute hidden m-auto left-0 right-0 z-30 w-[210px] border border-base-gray">
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/press"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Press
                    </Link>
                </li>
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/careers"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Careers
                    </Link>
                </li>
                <li className="bg-black px-3 text-xl">
                    <Link
                        to="/contact"
                        className="block text-white text-center hover:text-UL-green"
                    >
                        Contact
                    </Link>
                </li>
            </ul>
        </div>
    );
}

function AboutMobile() {
    return (
        <div className="relative group dropdown my-auto pt-10">
            <div className="text-center text-2xl relative mx-auto w-[150px] text-UL-green">
                About
            </div>
            <ul className="m-auto">
                <li className="px-3 text-xl">
                    <Link to="/team" className="block text-white text-center">
                        Our Team
                    </Link>
                </li>
                <li className="px-3 text-xl">
                    <Link
                        to="/mission"
                        className="block text-white text-center"
                    >
                        Our Mission
                    </Link>
                </li>
                <li className="px-3 text-xl">
                    <Link to="/story" className="block text-white text-center">
                        Our Story
                    </Link>
                </li>
            </ul>
        </div>
    );
}

function ServicesMobile() {
    return (
        <div className="relative group dropdown my-auto py-10">
            <div className="text-center text-2xl relative mx-auto w-[210px] text-UL-green">
                Services
            </div>
            <ul className="m-auto">
                <li className="px-3 text-xl">
                    <Link
                        to="/unlocked"
                        className="block text-white text-center"
                    >
                        UnlockEd
                    </Link>
                </li>
                <li className="px-3 text-xl">
                    <Link
                        to="/coding-education"
                        className="block text-white text-center"
                    >
                        Coding Education
                    </Link>
                </li>
                <li className="px-3 text-xl">
                    <Link
                        to="/tech-consulting"
                        className="block text-white text-center"
                    >
                        Tech Consulting
                    </Link>
                </li>
            </ul>
        </div>
    );
}

function LearnMoreMobile() {
    return (
        <div className="relative group dropdown my-auto pb-10">
            <div className="text-center text-2xl relative mx-auto w-[210px] text-UL-green">
                Learn More
            </div>
            <ul className="m-auto">
                <li className="px-3 text-xl">
                    <Link to="/press" className="block text-white text-center">
                        Press
                    </Link>
                </li>
                <li className="px-3 text-xl">
                    <Link
                        to="/careers"
                        className="block text-white text-center"
                    >
                        Careers
                    </Link>
                </li>
                <li className="px-3 text-xl">
                    <Link
                        to="/contact"
                        className="block text-white text-center"
                    >
                        Contact
                    </Link>
                </li>
            </ul>
        </div>
    );
}

export function Navbar() {
    const { isAuthenticated } = useContext(AuthContext);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const donateOnce =
        'https://app.hubspot.com/payments/pMwvfkd6C7R9Pq?referrer=PAYMENT_LINK';
    const donateMonthly =
        'https://app.hubspot.com/payments/pyfwjcZGV?referrer=PAYMENT_LINK';

    return (
        <>
            {mobileMenuOpen ? (
                <div className="w-full h-screen bg-base-gray z-10">
                    <div className="grid grid-col p-4">
                        <div className="flex flex-row justify-between content-center">
                            <Link to="/">
                                <img
                                    className="max-w-[100px] md:max-w-[80%]"
                                    src={logo}
                                    alt="Unlocked Labs Logo"
                                />
                            </Link>
                            <FaWindowClose
                                className="text-white"
                                onClick={toggleMobileMenu}
                            />
                        </div>
                        <AboutMobile />
                        <ServicesMobile />
                        <LearnMoreMobile />
                    </div>
                </div>
            ) : (
                <div className="flex flex-row justify-between bg-black p-4 lg:p-6 z-10">
                    <div>
                        <Link to="/">
                            <img
                                className="max-w-[100px] md:max-w-[80%]"
                                src={logo}
                                alt="Unlocked Labs Logo"
                            />
                        </Link>
                        <Link
                            to={'/login'}
                            className="px-4 py-2 hover:text-UL-green  text-black rounded-lg text-center text-xl font-normal"
                        >
                            {isAuthenticated ? 'Logout' : 'Login'}
                        </Link>
                    </div>
                    <div className="text-white lg:hidden">
                        <FaBars onClick={toggleMobileMenu} />
                    </div>
                    <div className="hidden lg:block lg:grid lg:grid-cols-3 lg:content-center">
                        <About />
                        <Services />
                        <LearnMore />
                    </div>
                    <div className="flex flex-row justify-end gap-2 content-center">
                        <a
                            href={donateOnce}
                            className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-md font-normal my-auto"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Donate Once
                        </a>
                        <a
                            href={donateMonthly}
                            className="px-5 py-2 bg-UL-green text-base-gray rounded-lg text-center text-md font-normal my-auto"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Donate Monthly
                        </a>
                    </div>
                </div>
            )}
        </>
    );
}
